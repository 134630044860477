import React, {useState, useEffect} from "react";
import { FormControlLabel, IconButton,CircularProgress,Button } from '@material-ui/core';
import RefreshIcon from '@mui/icons-material/Refresh';
import EditIcon from '@material-ui/icons/Edit';
import GetAppIcon from '@mui/icons-material/GetApp';
import MenuIcon from '@mui/icons-material/Menu';
//import SaveIcon from '@material-ui/icons/Save';
import DeleteIcon from '@mui/icons-material/Delete';
import {useNavigate,Link} from 'react-router-dom'

import { blue,red } from '@material-ui/core/colors';
import { DataGrid, GridToolbar,gridClasses } from '@mui/x-data-grid';
import Tooltip from '@mui/material/Tooltip';
//import ReactExport from "react-data-export";
import {Box} from '@mui/material/';
import { makeStyles } from '@mui/styles';


//import Alert from '@mui/material/Alert';
//import Paper from '@mui/material/Paper';
//import Input from '@mui/material/Input';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import AppBar from '@mui/material/AppBar';
import Alert from '@mui/material/Alert';
import Collapse from '@mui/material/Collapse';
import CloseIcon from '@mui/icons-material/Close';
import FileDownloadIcon from '@mui/icons-material/FileDownload';

import { blueGrey } from '@mui/material/colors';
import { createTheme } from '@mui/material/styles';

import Toolbar from '@mui/material/Toolbar';
import Menu from '@mui/material/Menu';
import MenuItem from '@material-ui/core/MenuItem';


import Typography from '@mui/material/Typography';
import { alpha, styled } from '@mui/material/styles';

import AuthService from "./services/auth.service";
//import AuthVerify from "./common/auth-verify";
import authHeader from "./services/auth-header";
import EventBus from "./common/EventBus";

import axios from "axios";

import moment from 'moment';

import { Document, Page } from 'react-pdf';

import * as pdfjsLib from 'pdfjs-dist';
import { useRef } from 'react';


pdfjsLib.GlobalWorkerOptions.workerSrc = `${process.env.PUBLIC_URL}/pdf.worker.min.js`;


//import AutoSizer from "react-virtualized-auto-sizer";

/*function CustomGridToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarExport />
    </GridToolbarContainer>
  );
}*/
//const color=indigo[200];
const ODD_OPACITY = 0.2;

const StripedDataGrid = styled(DataGrid)(({ theme }) => ({
  [`& .${gridClasses.row}.even`]: {
    backgroundColor: theme.palette.grey[200],
    '&:hover, &.Mui-hovered': {
      backgroundColor: alpha(theme.palette.primary.main, ODD_OPACITY),
      '@media (hover: none)': {
        backgroundColor: 'transparent',
      },
    },
    '&.Mui-selected': {
      backgroundColor: alpha(
        theme.palette.primary.main,
        ODD_OPACITY + theme.palette.action.selectedOpacity,
      ),
      '&:hover, &.Mui-hovered': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          ODD_OPACITY +
            theme.palette.action.selectedOpacity +
            theme.palette.action.hoverOpacity,
        ),
        // Reset on touch devices, it doesn't add specificity
        '@media (hover: none)': {
          backgroundColor: alpha(
            theme.palette.primary.main,
            ODD_OPACITY + theme.palette.action.selectedOpacity,
          ),
        },
      },
    },
  },
}));

const theme = createTheme({
  palette: {
    primary: {
      main: blueGrey[50],
    },
    secondary: {
      main: '#f44336',
    },
  },
});

const useStyles = makeStyles({
 root: {
   '.MuiDataGrid-columnHeaderTitleContainer': {
     justifyContent: 'center',
   },
 },
});


const PendingPayments=() => {

  document.title = "Admin Invoice Page";

  const [isLoading,setIsLoading] = useState(false);

  const [pendingPayments,setPendingPayments] = useState([]);
  const [invoices,setInvoices] = useState([]);

  const [regtoEdit,setRegtoEdit]=useState();
  //const [regtoDel,setRegtoDel]=useState();

  //const [editId,setEditid]=useState(false);
  //const [delId,setDelid]=useState(false);

  const [open,setOpen]=useState(false);
  const [openphoto,setOpenPhoto]=useState(false);


  const [delOpen,setDelOpen]=useState(false);
  const navigate=useNavigate();

  const [currentUser, setCurrentUser] = useState(undefined);
  const [currentPage, setCurrentPage] = useState("Payments");

  const [alertopen,setAlertOpen]=useState(false);
  const [alertmessage,setAlertMessage]=useState("");
  const [severity,setSeverity]=useState('success');

  const [pageSize, setPageSize] = useState(10);


  const [scrollY,setScrollY]=useState("0");

  const [selectedFile,setSelectedFile]=useState(null);

  /*appbar*/
    const [anchorEl, setAnchorEl] = React.useState(null);
    const pages = ['Payments', 'Invoices'];

  //  const dataGrid = useRef(null);
    //const dataGrid1 = useRef(null);



    const handleChange = (event) => {
    //  setAuth(event.target.checked);
    };



    const handleMenu = (event) => {
      setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
      setAnchorEl(null);
    };
    /*appbar*/

    const classes = useStyles();

    const openPhoto = async (path,id,scrollY) => {
    //let photourl;
    setIsLoading(true);
    let myHeaders = authHeader();
    const API_URL = process.env.REACT_APP_BACKEND_URL+"/services/file?fileName="+path;
    //console.log("***",id,`${process.env.PUBLIC_URL}/pdf.worker.min.js`);
    setScrollY(scrollY);
    setRegtoEdit(pendingPayments.filter(p=> p.installmentId===id).map((filtered,i) =>  {return filtered}));

    //console.log(pendingPayments.filter(p=> p.installmentId===id).map((filtered,i) =>  {return filtered}));

    const d= await axios.get(API_URL
        ,{responseType: "blob",  headers:myHeaders})
        .then(response => {
          //console.log(response.data);
          setSelectedFile(URL.createObjectURL(response.data));
        }).catch(err=>{
          EventBus.dispatch("logout");
          //alert("Cannot contact server." +err);
            navigate("/admin/Login");
            setIsLoading(false);
              //setOpen(false);
          //  setCurrentUser(undefined);
        }).then(r =>{        //setOpen(true);
                //console.log(open);console.log(selectedFile);
                setIsLoading(false);
              //  setOpen(true);

              })
    };



/*    const handleExport  =async () => {
  //    console.log("Clicked");
      setIsLoading(true);
      const myHeaders = authHeader();

      const options = {
        method: 'GET',
        responseType: 'blob',
       headers:myHeaders
      };

      const API_URL = process.env.REACT_APP_BACKEND_URL+"/services/globalexcel";


    //  let res = await fetch(API_URL,options);

    fetch(API_URL,options)
    .then(response => {
      response.blob().then(blob => {
        let url = window.URL.createObjectURL(blob);
        let a = document.createElement('a');
        a.href = url;
        a.download = 'GlobalExcel.xlsx';
        a.click();
        setTimeout(function () {
          window.URL.revokeObjectURL(a);
        }, 200);

      });
      //window.location.href = response.url;
    })
    .then(r=>{setIsLoading(false);})
    .catch(err=>{
    EventBus.dispatch("logout");
    alert("Cannot contact server." +err);
    navigate("/admin/Login");
      setIsLoading(false);})
    ;


  };*/


  const handleSave =async () => {

  const myHeaders = authHeader();


  if (open) { //Save Edit Payment
  setIsLoading(true);
  const API_URL = process.env.REACT_APP_BACKEND_URL+"/services/updateInstallment";
    const options = {
      method: 'POST',
     body:JSON.stringify({
       installmentId:regtoEdit[0].installmentId,name: regtoEdit[0].name,
       lastName: regtoEdit[0].lastName,
       code:regtoEdit[0].code,organisationName:regtoEdit[0].organisationName,
       organisationPresident:regtoEdit[0].organisationPresident,
       email:regtoEdit[0].email,phone:regtoEdit[0].phone,country:regtoEdit[0].country,
       amount:regtoEdit[0].amount,pendingPayment:!regtoEdit[0].pendingPayment,paid:!regtoEdit[0].paid,
       cardPayment:regtoEdit[0].cardPayment,lastFourDigits:regtoEdit[0].lastFourDigits,
       cancelled:regtoEdit[0].cancelled, refundDate:regtoEdit[0].refundDate, refundStatus:regtoEdit[0].refundStatus,
          comment:regtoEdit[0].comment,  paymentType:regtoEdit[0].paymentType
     }),
     headers:myHeaders
    };
   let res = await fetch(API_URL,options);
   if (res.ok)
    {
    let responseJson = await res.json()
   .then(async s=>{
     let data;
     const API_URL1 = process.env.REACT_APP_BACKEND_URL+"/services/installments";
     const response = axios.get(API_URL1, { headers:myHeaders}).then(response=>{
       return response.data;}).then(data=>
         {
         setPendingPayments(data);setIsLoading(false);setOpen(false);
         setSeverity("success");setAlertMessage("Successful editted payment with id: "+regtoEdit[0].installmentId);
         setAlertOpen(true);
       })
       .catch(err=>{
         setIsLoading(false);setOpen(false);alert("Error while contacting server. "+err);
         setSeverity("error");
         setAlertMessage("Unsuccessful edit!");setAlertOpen(true);
         EventBus.dispatch("logout");navigate("/admin/Login");});

         const API_URL2 = process.env.REACT_APP_BACKEND_URL+"/services/invoices";
         const response2 = axios.get(API_URL2,{ headers:myHeaders}).then( response2=>{
           //console.log(response2);
           return response2.data;}).then(data=>
             {
             //console.log(data);
             //console.log(confidentialList);
             const updatedPayments = data.map(payment => ({
        ...payment,
        remainingAmount: (payment.totalAmount - payment.amountPaid) > 0 ? (payment.totalAmount - payment.amountPaid) : 0
       }));

             setInvoices(updatedPayments);
             setIsLoading(false);
             //console.log(updatedPayments);

           //  console.log("LOAD false");
         }).catch(err=>{
           EventBus.dispatch("logout");
           setAlertMessage("Cannot contact server." +err);
             navigate("/admin/Login");
             setIsLoading(false);
         })


       })
    .catch(err=>{ setSeverity("error");
                setAlertMessage("Unsuccessful edit!");
                setAlertOpen(true);setIsLoading(false);setOpen(false);alert("Error while editting. "+err);
                EventBus.dispatch("logout");
                navigate("/admin/Login");
              })
    }
    else {
      setSeverity("error");
      setAlertMessage("Unsuccessful edit!");
      setAlertOpen(true);setIsLoading(false);setOpen(false);alert("Error while contacting server. "+res);
      EventBus.dispatch("logout");
      navigate("/admin/Login");

    }
   }
   else {//Actions to Refund/ Delete Installment
     setIsLoading(true);
     const COMP_API_URL = process.env.REACT_APP_BACKEND_URL+"/services/deleteInstallment";

     const params={installmentId:regtoEdit[0].installmentId};
     const queryString = new URLSearchParams(params).toString();
     //console.log(queryString);
     const options = {
       method: 'DELETE',
       headers:myHeaders
     };

     let res = await fetch(COMP_API_URL+"?"+queryString,options);
     if (res.ok)
      {
      let responseJson = await res.json().
     then(async d=>
         {//Upload Success
           let data;
           const API_URL1 = process.env.REACT_APP_BACKEND_URL+"/services/installments";
           const response = axios.get(API_URL1, { headers:myHeaders})
           .then( response=>{return response.data;})
           .then(data=>
               {
            //   console.log(data);
               setPendingPayments(data);setIsLoading(false);setDelOpen(false);
               setSeverity("success");
               setAlertMessage("Successful delete/refund of payment with id " +regtoEdit[0].installmentId);
               setAlertOpen(true);

             })
             .catch(err=>{        setSeverity("error");
                    setAlertMessage("Unsuccessful delete!");
                    setAlertOpen(true);setIsLoading(false);setDelOpen(false);setAlertMessage("Error while contacting server. "+err);
                    EventBus.dispatch("logout");
                    navigate("/admin/Login");
                  });

                  const API_URL2 = process.env.REACT_APP_BACKEND_URL+"/services/invoices";
                  const response2 = axios.get(API_URL2,{ headers:myHeaders}).then( response2=>{
                    //console.log(response2);
                    return response2.data;}).then(data=>
                      {
                      //console.log(data);
                      //console.log(confidentialList);
                      const updatedPayments = data.map(payment => ({
     ...payment,
     remainingAmount: (payment.totalAmount - payment.amountPaid) > 0 ? (payment.totalAmount - payment.amountPaid) : 0
    }));

                      setInvoices(updatedPayments);
                      setIsLoading(false);
                      //console.log(updatedPayments);

                    //  console.log("LOAD false");
                  }).catch(err=>{
                    EventBus.dispatch("logout");
                    setAlertMessage("Cannot contact server." +err);
                      navigate("/admin/Login");
                      setIsLoading(false);
                  })
           })
       .catch(err=>{       setSeverity("error");
              setAlertMessage("Unsuccessful delete!");
              setAlertOpen(true);setIsLoading(false);setDelOpen(false);setAlertMessage("Error while deleting. "+err);
              EventBus.dispatch("logout");
              navigate("/admin/Login");
            })
          }
          else {
            //console.log(res);
            setSeverity("error");
            setAlertMessage("Unsuccessful delete!");
            setAlertOpen(true);setIsLoading(false);setOpen(false);setAlertMessage("Error while contacting server. "+res);
            EventBus.dispatch("logout");
            navigate("/admin/Login");
          }
   }

  };


  const handleCancel = (value: string) => {
  setOpen(false);
  setDelOpen(false);
  };

  const MatEdit = ({ index }) => {
    //const apiRef = useGridApiContext();

      const handleEditClick = () => {
        setRegtoEdit(pendingPayments.filter(p=> p.installmentId===index).map((filtered,i) =>  {return filtered}));
        //setEditid(index);

        //Only not Cancelled and not Card paid
        if(!pendingPayments.filter(p=> p.installmentId===index).map((filtered,i) => {return filtered.cancelled})[0] &&
        !pendingPayments.filter(p=> p.installmentId===index).map((filtered,i) => {return filtered.cardPayment})[0])
          setOpen(true);
      }
      const handleDeleteClick = () => {
          setRegtoEdit(pendingPayments.filter(p=> p.installmentId===index).map((filtered,i) =>  {return filtered}));
          //setEditid(index);
          //Only not Cancelled
          if(!pendingPayments.filter(p=> p.installmentId===index).map((filtered,i) => {return filtered.cancelled})[0])
            setDelOpen(true);
      }


      return <FormControlLabel
           control={
             <Box>
               <IconButton color="secondary" aria-label="add an alarm" onClick={handleEditClick} >
                   <EditIcon style={{ color: blue[500] }} />
               </IconButton>
               <IconButton color="secondary" aria-label="add an alarm" onClick={handleDeleteClick} >
                   <DeleteIcon style={{ color: red[500] }} />
               </IconButton>
               </Box>
           }
             />
    };


  const columns: GridColDef[] = [
    {
       field: "actions",
       headerName: "Actions",
       sortable: false,
       width:100,
       disableClickEventBubbling: true,
       renderCell: (params) => {
           return (
               <div className="actions" style={{ cursor: "pointer" }}>
                   <MatEdit index={params.row.installmentId} />
                </div>
           );
        }

     },
  { field: 'installmentId', headerName: 'ID', width: 50, type:'number',  renderCell: (params: any) =>  (
       <Tooltip title={params.value} >
        <span className="table-cell-trucate">{params.value}</span>
        </Tooltip>)},
  { field: 'installmentDate',flex: 0.3, headerName: 'Payment Date', minWidth: 100,type:'string',
      valueGetter: (params) => (params.row.installmentDate!==""?`${moment(params.row.installmentDate).format('YYYY-MM-DD')}`:"")
      },
  { field: 'name',flex: 0.5, headerName: 'Name' , minWidth: 100,type:'string',  renderCell: (params: any) =>  (
       <Tooltip title={params.value} >
        <span className="table-cell-trucate">{params.value}</span>
        </Tooltip>)},
  { field: 'lastName',flex: 0.5, headerName: 'Last Name', minWidth: 100,type:'string',  renderCell: (params: any) =>  (
       <Tooltip title={params.value} >
        <span className="table-cell-trucate">{params.value}</span>
        </Tooltip>)},
  { field: 'lastFourDigits', headerName: 'Last Four Digits', minWidth: 100, type:'number',  renderCell: (params: any) =>  (
       <Tooltip title={params.value} >
        <span className="table-cell-trucate">{params.value}</span>
        </Tooltip>)},
        { field: 'invoiceNumber', headerName: 'Invoice Number', minWidth: 100, type:'number',  renderCell: (params: any) =>  (
             <Tooltip title={params.value} >
              <span className="table-cell-trucate">{params.value}</span>
              </Tooltip>)},
  { field: 'amount',flex: 0.3, headerName: 'Amount', minWidth: 100,type:'number',
valueFormatter: (params) => {
              if (params.value == null) {
                return '';
              }
              const valueFormatted = Number(params.value);
              return `${valueFormatted} $`;
            }
          },
  {
   field: 'filePath',
   flex: 0.5,
   headerName: 'Receipt File',
   minWidth: 100,
   type: 'string',
   renderCell: (params: any) => (
   <Tooltip title={params.value}>
     {params.value ? (
       <Button
         variant="contained"
         color="primary"
         onClick={async() =>{
           //console.log(selectedFile);
           await openPhoto(params.value,params.id,scrollY);
           setOpenPhoto(true);
          }}
       >
         Open File
       </Button>
     ) : (
       <span className="table-cell-truncate">{params.value}</span>
     )}
   </Tooltip>
   ),
  }


,
  { field: 'email',flex: 0.5, headerName: 'Email', minWidth: 200,type:'string',sortable:false,  renderCell: (params: any) =>  (
       <Tooltip title={params.value} >
        <span className="table-cell-trucate">{params.value}</span>
        </Tooltip>)},
  { field: 'country',flex: 0.3, headerName: 'Country', minWidth: 100,type:'string',  renderCell: (params: any) =>  (
       <Tooltip title={params.value} >
        <span className="table-cell-trucate">{params.value}</span>
        </Tooltip>)},
  { field: 'paymentType',flex: 0.5, headerName: 'Payment Type', minWidth: 150,type:'string',  renderCell: (params: any) =>  (
       <Tooltip title={params.value} >
        <span className="table-cell-trucate">{params.value}</span>
        </Tooltip>)},
  { field: 'pendingPayment',flex: 0.3, headerName: 'Pending', minWidth: 70,type:'boolean'},
  { field: 'paid',flex: 0.3, headerName: 'Is Paid', minWidth: 70,type:'boolean'},
  { field: 'cardPayment',flex: 0.3, headerName: 'Card Payment', minWidth: 70,type:'boolean'},
  { field: 'cancelled',flex: 0.3, headerName: 'Cancelled', minWidth: 80,type:'boolean'},
  { field: 'refundStatus',flex: 0.3, headerName: 'Refund Status', minWidth: 70,type:'boolean'},
  { field: 'refundDate',flex: 0.3, headerName: 'Refund Date', minWidth: 100,type:'string',
valueGetter: (params) => (params.row.refundDate!==""?`${moment(params.row.refundDate).format('YYYY-MM-DD')}`:"")

},
{ field: 'comment',flex: 0.5, headerName: 'Comment', minWidth: 150,type:'string',  renderCell: (params: any) =>  (
     <Tooltip title={params.value} >
      <span className="table-cell-trucate">{params.value}</span>
      </Tooltip>)},
      { field: 'code',flex: 0.4, headerName: 'Organisation Code', minWidth: 100,type:'string',  renderCell: (params: any) =>  (
           <Tooltip title={params.value} >
            <span className="table-cell-trucate">{params.value}</span>
            </Tooltip>)},
{ field: 'organisationName',flex: 0.4, headerName: 'Organisation Name', minWidth: 150,type:'string',  renderCell: (params: any) =>  (
     <Tooltip title={params.value} >
      <span className="table-cell-trucate">{params.value}</span>
      </Tooltip>)},
{ field: 'organisationPresident',flex: 0.4, headerName: 'Organisation President', minWidth: 150,type:'string',  renderCell: (params: any) =>  (
     <Tooltip title={params.value} >
      <span className="table-cell-trucate">{params.value}</span>
      </Tooltip>)},
{ field: 'phone',flex: 0.2, headerName: 'Phone', minWidth: 150,type:'string',sortable:false,  renderCell: (params: any) =>  (
     <Tooltip title={params.value} >
      <span className="table-cell-trucate">{params.value}</span>
      </Tooltip>)},
];

const columns2: GridColDef[] = [

{ field: 'invoiceNumber', headerName: 'Invoice Number', minwidth: 100, type:'number',  renderCell: (params: any) =>  (
     <Tooltip title={params.value} >
      <span className="table-cell-trucate">{params.value}</span>
      </Tooltip>)},
{ field: 'dueDate',flex: 0.3, headerName: 'Due Date', minWidth: 100,type:'string',
    valueGetter: (params) => (params.row.dueDate!==""?`${moment(params.row.dueDate).format('YYYY-MM-DD')}`:"")
    },
{ field: 'fullyPaid',flex: 0.3, headerName: 'Fully Paid', minWidth: 70,type:'boolean'},

{ field: 'amountPaid',flex: 0.3, headerName: 'Amount Paid', minWidth: 100,type:'number',
valueFormatter: (params) => {
            if (params.value == null) {
              return '';
            }
            const valueFormatted = Number(params.value);
            return `${valueFormatted} $`;
          }
        },
  { field: 'totalAmount',flex: 0.3, headerName: 'Total Amount',      headerAlign: 'center',
 minWidth: 100,type:'number',
  valueFormatter: (params) => {
              if (params.value == null) {
                return '';
              }
              const valueFormatted = Number(params.value);
              return `${valueFormatted} $`;
            }
          },
  {
      field: 'remainingAmount',
      flex: 0.3,
      headerName: 'Remaining Amount',
      headerAlign: 'center',
      minWidth: 100,
      type: 'number',
      valueFormatter: (params) => {
                  if (params.value == null) {
                    return '';
                  }
                  const valueFormatted = Number(params.value);
                  return `${valueFormatted} $`;
                }


    },

          {
          field: 'pendingAmount',
          flex: 0.3,
          headerName: 'Pending Amount',
          headerAlign: 'center',
          minWidth: 100,
          type: 'number',
          valueFormatter: (params) => {
                    if (params.value == null) {
                      return '';
                    }
                    const valueFormatted = Number(params.value);
                    return `${valueFormatted} $`;
                  }


          },

{ field: 'country',flex: 0.3, headerName: 'Country', minWidth: 100,type:'string',  renderCell: (params: any) =>  (
     <Tooltip title={params.value} >
      <span className="table-cell-trucate">{params.value}</span>
      </Tooltip>)},
{ field: 'paymentType',flex: 0.5, headerName: 'Payment Type', minWidth: 150,type:'string',  renderCell: (params: any) =>  (
     <Tooltip title={params.value} >
      <span className="table-cell-trucate">{params.value}</span>
      </Tooltip>)},


{ field: 'organisationName',flex: 0.4, headerName: 'Organisation Name', minWidth: 150,type:'string',  renderCell: (params: any) =>  (
   <Tooltip title={params.value} >
    <span className="table-cell-trucate">{params.value}</span>
    </Tooltip>)},
{ field: 'president',flex: 0.4, headerName: 'Organisation President', minWidth: 150,type:'string',  renderCell: (params: any) =>  (
   <Tooltip title={params.value} >
    <span className="table-cell-trucate">{params.value}</span>
    </Tooltip>)},
];


  const rows: GridRowsProp = pendingPayments;
  const rows2: GridRowsProp = invoices;

  const parseJwt = (token) => {
  try {
    return JSON.parse(atob(token.split('.')[1]));
  } catch (err) {
    alert("Authentication error. " + err);
    return null;
  }
};

const fetchData = async () => {
 setIsLoading(true);

 try {
   if (AuthService.getCurrentUser()) {
     setCurrentUser(AuthService.getCurrentUser());
     if (AuthService.getCurrentUser()) {
       const decodedJwt = parseJwt(AuthService.getCurrentUser().accessToken);

       if (decodedJwt.exp * 1000 < Date.now()) {
         EventBus.dispatch("logout");
         navigate("/admin/Login");
       }
     }
   } else {
     EventBus.dispatch("logout");
     navigate("/admin/Login");
   }

   const myHeaders = authHeader();
   const API_URL1 = process.env.REACT_APP_BACKEND_URL+"/services/installments";
   const response = await axios.get(API_URL1,{ headers:myHeaders});
   const data = response.data;
  // console.log(response.data);
   setAlertOpen(true);
   setAlertMessage("Data refreshed!");
   setPendingPayments(data);

   const API_URL2 = process.env.REACT_APP_BACKEND_URL+"/services/invoices";
   const response2 = await axios.get(API_URL2,{ headers:myHeaders});
   const data2 = response2.data;
  //  console.log(response2.data);
  const updatedPayments = data2.map(payment => ({
 ...payment,
 remainingAmount: (payment.totalAmount - payment.amountPaid) > 0 ? (payment.totalAmount - payment.amountPaid) : 0
}));
   setInvoices(updatedPayments);
 } catch (err) {
   EventBus.dispatch("logout");
   setAlertMessage("Cannot contact server." + err);
   navigate("/admin/Login");
 } finally {
   setIsLoading(false);
 }
};

// const RefreshData = async () => {
//
//   if (dataGrid.current) {
//  console.log(dataGrid.current);
//  // Save the current sort model, filter model, and pagination state
//  let savedSortModel = dataGrid.current.state.sortModel;
//  let savedFilterModel = dataGrid.current.state.filterModel;
//  let savedPageSize = dataGrid.current.state.pageSize;
//  let savedPage = dataGrid.current.state.page;
//
//  // Fetch new data
//  await fetchData();
//
//  // Restore the sort model, filter model, and pagination state
//  dataGrid.current.setState(prevState => ({
//    ...prevState,
//    sortModel: savedSortModel,
//    filterModel: savedFilterModel,
//    pageSize: savedPageSize,
//    page: savedPage,
//  }));
//
// }
// else  await fetchData();
//
// };



  useEffect(() => {
     const myHeaders = authHeader();
     setIsLoading(true);


     if (AuthService.getCurrentUser())
     {
       setCurrentUser(AuthService.getCurrentUser());
       if (AuthService.getCurrentUser()) {
         const decodedJwt = parseJwt(AuthService.getCurrentUser().accessToken);

         if (decodedJwt.exp * 1000 < Date.now()) {
           EventBus.dispatch("logout");
           navigate("/admin/Login");
         }
       }
     }
     else {
       EventBus.dispatch("logout");
       navigate("/admin/Login");
     };

    const API_URL1 = process.env.REACT_APP_BACKEND_URL+"/services/installments";
    const response = axios.get(API_URL1,{ headers:myHeaders}).then( response=>{
      //console.log(response);
      return response.data;}).then(data=>
        {
        //console.log(data);
        setAlertOpen(true);
        setAlertMessage("Data refreshed!");
        setPendingPayments(data);
        setIsLoading(false);

      //  console.log("LOAD false");
    }).catch(err=>{
      EventBus.dispatch("logout");
      //alert("Cannot contact server." +err);
        navigate("/admin/Login");
        setIsLoading(false);
    })

    const API_URL2 = process.env.REACT_APP_BACKEND_URL+"/services/invoices";
    const response2 = axios.get(API_URL2,{ headers:myHeaders}).then( response2=>{
      //console.log(response2);
      return response2.data;}).then(data=>
        {
        //console.log(data);
        //console.log(confidentialList);
        const updatedPayments = data.map(payment => ({
     ...payment,
     remainingAmount: (payment.totalAmount - payment.amountPaid) > 0 ? (payment.totalAmount - payment.amountPaid) : 0
    }));
        setInvoices(updatedPayments);
        setIsLoading(false);
        //console.log(updatedPayments);

      //  console.log("LOAD false");
    }).catch(err=>{
      EventBus.dispatch("logout");
      setAlertMessage("Cannot contact server." +err);
        navigate("/admin/Login");
        setIsLoading(false);
    })
},[]);


  return (


<div >
<div className="pendingpayments">
    <Dialog
      open={(open||delOpen)}
      //onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      {open&&<DialogTitle id="alert-dialog-title">
        Change status payment to: {regtoEdit&&regtoEdit[0].pendingPayment?'Paid':'Pending'}
      </DialogTitle>}
      {delOpen&&<DialogTitle id="alert-dialog-title">
      {regtoEdit[0].cardPayment?"Deleting payment will initiate the automatic stripe refund process. Are you sure you want to proceed?":
      "Delete payment?"}
        </DialogTitle>}
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
        Installment Id: {regtoEdit&&regtoEdit[0].installmentId}<br/>
        Name: {regtoEdit&&regtoEdit[0].name} {regtoEdit&&regtoEdit[0].lastName}<br/>
        Amount: {regtoEdit&&regtoEdit[0].amount} $
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button disabled={isLoading} onClick={handleCancel}>No</Button>
        {open&&<Button disabled={isLoading} onClick={handleSave} autoFocus>Yes</Button>}
        {delOpen&&<Button disabled={isLoading} onClick={handleSave} autoFocus>{regtoEdit[0].cardPayment?"Proceed to Refund":"Delete Payment"}</Button>}
      </DialogActions>
    </Dialog>

    {selectedFile && (
 <Dialog open={openphoto} onClose={() => setOpen(false)}  PaperProps={{ style: { maxHeight: '90vh', width: '90vw' } }}>
 <DialogTitle>
 <Typography variant="h7" component="div" sx={{ flexGrow: 1 }}>
 Receipt/Payment ID: {regtoEdit[0].installmentId}
 </Typography>
  <Tooltip title="Close">
  <IconButton edge="end" color="inherit" onClick={() => setOpenPhoto(false)} aria-label="close">
   <CloseIcon />
  </IconButton>
  </Tooltip>
  <Tooltip title="Download">
 <IconButton
   color="primary"
   onClick={(e) => { //Download File
     e.stopPropagation();
     const link = document.createElement('a');
     link.href = selectedFile;
     //console.log(selectedFile);
     let filename = regtoEdit[0].filePath;
     filename = filename.replace(/\//g, '_');
     link.download = filename;
     document.body.appendChild(link);
     link.click();
     document.body.removeChild(link);
   }}
 >
   <GetAppIcon />
 </IconButton>
</Tooltip>


 </DialogTitle>
 <DialogContent>
  {regtoEdit[0].filePath.endsWith('jpg') || regtoEdit[0].filePath.endsWith('jpeg') || regtoEdit[0].filePath.endsWith('.png') || regtoEdit[0].filePath.endsWith('.tiff') ? (
   <img
    key={'p'}
    id="photo"
    src={selectedFile}
    alt="Selected File"
    style={{maxWidth: '100%', height: 'auto'}}
   />
  ) : selectedFile&&regtoEdit[0].filePath.endsWith('.pdf') ? (
  <Document file={selectedFile}>
    <Page pageNumber={1} />
  </Document>
 ) : (
  <iframe
   src={selectedFile}
   style={{width: '100%', height: '100%'}}
   title="PDF Viewer"
   allowFullScreen
  />
 )}

 </DialogContent>
 </Dialog>
)}


    {isLoading &&
    <div style={{ alignItems: "center", display: "flex", justifyContent: "center" }}>
                  <CircularProgress color="secondary" />
    <span style={{ justifyContent: "center", position: "fixed"}}>Loading...please wait</span>
    </div>
    }
    </div>
<div>
{currentUser && (

  <AppBar theme={theme}
 position="static">
        <Toolbar>
          <IconButton
            size="small"
            variant="dense"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 2 }}
            onClick={handleMenu}

          >
            <MenuIcon />
          </IconButton>

          <Typography variant="h7" component="div" sx={{ flexGrow: 1 }}>
{"Admin Panel/"}{currentPage}
          </Typography>
          <Menu
    id="simple-menu"
    anchorEl={anchorEl}
    keepMounted
    open={Boolean(anchorEl)}
    onClose={handleClose}
   >
   {pages.map((page) => (

    <MenuItem  key={page.toString()}    onClose={handleClose}   onClick={() => {setCurrentPage(page);handleClose();}}>
     {page}
    </MenuItem>

       ))}


   </Menu>


          <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
           {pages.map((page) => (
             <Button
               key={page}
               onClick={() => {setCurrentPage(page)}}
               sx={{ my: 2, color: 'white', display: 'block' }}
               style={{ boxShadow: page===currentPage ? '0px 3px 5px 2px rgba(0,0,0,0.3)' : '' }}
             >
               {page}
             </Button>
           ))}

         </Box>
         <Button color="primary" startIcon={<RefreshIcon />} onClick={fetchData}
disabled={isLoading} color="inherit" style={{ boxShadow: '0px 3px 5px 2px rgba(0,0,0,0.3)' }}
         >
         Refresh Data
         </Button>

          <Button disabled={isLoading} color="inherit" style={{ boxShadow: '0px 3px 5px 2px rgba(0,0,0,0.3)' }}>      <Link style={{ textDecoration: 'none', color: 'black'  }} to={"/admin/Login"}>
                  {"Logged in as "+currentUser.username}
                </Link></Button>
        </Toolbar>
      </AppBar>

)}
{!isLoading&&<Box sx={{ width: '100%' }}>
     <Collapse in={alertopen}>
       <Alert severity={severity}
         action={
           <IconButton
             aria-label="close"
             color="inherit"
             size="small"
             onClick={() => {
               setAlertOpen(false);
             }}
           >
             <CloseIcon fontSize="inherit" />
           </IconButton>
         }
         sx={{ mb: 2 }}
       >
        {alertmessage}
       </Alert>
     </Collapse>
   </Box>}

           {currentPage==="Payments"&&<Box style={{ height:  600 , width: '100%' }}
           sx={{

          '& .red': {
            //backgroundColor:  '#DCE1E5','#EF5645'
            backgroundColor:  '#EF5645',
            color: '#1a3e72',
          },
          '& .orange': {
            backgroundColor: '#FFD858',
            color: '#1a3e72',
          },
          '& .green': {
            backgroundColor: '#89E682',
            color: '#1a3e72',
          },
        }}
           >
           {/*color="#e20e14"*/}
           <Typography variant="h5"  gutterBottom component="div">

                </Typography>

           <StripedDataGrid
           rowsPerPageOptions={[ 10, 25,100]}
           pageSize={pageSize}
           onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
           pagination
          loading={isLoading}  initialState={{sorting:{sortModel:[{field:'installmentId',sort:'asc'}]}}}
                disableExtendRowFullWidth editMode="row" sortingOrder={['asc','desc']}
           getRowId={rows => rows.installmentId}
           getRowClassName={(params) =>
  params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
}

           getCellClassName={(params: GridCellParams<number>) => {
             if (params.field === 'pendingPayment' && params.value === true) {
               return params.value ===true ? 'orange' : 'cold';
             }
             if (params.field === 'paid' && params.value === true) {
               return params.value ===true ? 'green' : 'orange';
             }
             if (params.field === 'cancelled' && params.value === true) {
               return params.value ===true ? 'red' : 'cold';
             }
            //  else return 'cold'
              }}

           rows={rows} columns={columns}
            components={{ Toolbar: GridToolbar }} experimentalFeatures={{ newEditingApi: true }}
            sx={{
                        '& .MuiDataGrid-columnHeaderTitle': {
                            textOverflow: "clip",
                            whiteSpace: "break-spaces",
                            lineHeight: 1
                        }
                    }}

            />
     </Box>}
     {currentPage==="Invoices"&&<Box style={{ height:  600 , width: '100%' }}
     sx={{

    '& .red': {
      //backgroundColor:  '#DCE1E5','#EF5645'
      backgroundColor:  '#EF5645',
      color: '#1a3e72',
    },
    '& .orange': {
      backgroundColor: '#FFD858',
      color: '#1a3e72',
    },
    '& .green': {
      backgroundColor: '#89E682',
      color: '#1a3e72',
    },
  }}
     >
     {/*color="#e20e14"*/}
     <Typography variant="h5"  gutterBottom component="div">

          </Typography>

     <StripedDataGrid className={classes.root}

     rowsPerPageOptions={[ 10, 25,100]}
     pageSize={pageSize}
     onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
     pagination
    loading={isLoading}  initialState={{sorting:{sortModel:[{field:'invoiceNumber',sort:'asc'}]}}}
          disableExtendRowFullWidth editMode="row" sortingOrder={['asc','desc']}
     getRowId={rows => rows.invoiceNumber}
     getRowClassName={(params) =>
params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
}

     getCellClassName={(params: GridCellParams<number>) => {
       if (params.field === 'fullyPaid' && params.value === true) {
         return params.value ===true ? 'green' : 'red';
       }
      //  else return 'cold'
        }}

     rows={rows2} columns={columns2}
      components={{ Toolbar: GridToolbar }} experimentalFeatures={{ newEditingApi: true }}
      sx={{
                  '& .MuiDataGrid-columnHeaderTitle': {
                      textOverflow: "clip",
                      whiteSpace: "break-spaces",
                      lineHeight: 1
                  }
              }}

      />
</Box>}



{isLoading &&
<div style={{ alignItems: "center", display: "flex", justifyContent: "center" }}>
              <CircularProgress color="secondary" />
<span style={{ justifyContent: "center", position: "fixed", top: "55%" }}>Loading...please wait</span>
</div>
}


</div>
</div>

  );

}

export default PendingPayments;
